#home {

    .container {
        padding-top: 10rem;
    }

    .home-img {
        background-image: url("../assets/images/myImage.jpg");
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: cover;
        border: 3px solid #2d2e32;
        border-radius: 50%;
        height: 35rem;
        position: relative;
        transition: all 1s ease-in-out;
        width: 35rem;
        margin: auto;
    }

    .home-text {
        max-width: 100%;
        text-align: center;
        margin: auto;
    }

    h1 {
        font-weight: bolder;
        margin-bottom: 2rem;
        font-size: 4.5rem;
    }

    p {
        font-size: 2rem;
    }

    .skills {
        margin-top: 5rem;


        .techStack {
            font-weight: bold;
            text-align: center;
            padding: auto
        }

        .stackList {
            display: flex;
            flex-wrap: wrap;
            gap: 5rem;
            list-style: none;

            .stackList-item {
                align-items: center;
                cursor: pointer;
                display: flex;
                justify-content: center;
            

                img {
                    height: auto;
                    width: 10rem;
                }
            }
        }

    }

}