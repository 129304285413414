#projects {
    padding-top: 10rem;

    h1 {
        text-align: center;
        margin-bottom: 3rem;
        font-weight: bolder;
        font-size: 3.5rem;
    }

    h3 {
        text-align: center;
        font-size: 1.8rem;
    }

    .ProjectBox {
        border-radius: 2rem;
        box-shadow: 0 0 15px darkcyan;
        padding: 1.5rem;
        margin: 2.5rem 0.5rem;

        img {
            width: 100%;
            border-radius: 0.5rem;
            box-shadow: 0 0 5px darkcyan;
        }

        .ProjectBox-info {
            margin: 1.5rem 0;

            h3 {
                font-weight: bolder;
                font-size: 2.5rem;
                margin-bottom: 1rem;
            }

            .ProjectBox-tech {
                display: flex;
                flex-wrap: wrap;
                gap: 1rem;
                margin: 1rem 0;

                p {
                    border-radius: 0.5rem;
                    box-shadow: 0 0 5px darkcyan;
                    padding: 0.5rem;
                }

            }

            .ProjectBox-link {

                a { 
                    color: darkcyan;
                    text-decoration: none;
                }

                a:hover {
                    color:cyan;
                }
            }

        }
    }

}