#darkMode {
	background: transparent url("../assets/images/moon.png") no-repeat center;
	background-size: 30px 30px;
	width: 45px;
	height: 45px;
	filter: grayscale(100%);
	border: none;
	border-radius: 50%;
	background-color: white;
	transition: background-color 0.3s ease-in-out, filter 0.3s ease-in-out;
	margin: 1rem;
}

#darkMode:hover,
#darkMode:focus {
	filter: none;
	background-color: black;
	cursor: pointer;
}

#darkMode.clicked {
	filter: none !important;
	background-color: black;
}

#darkMode.clicked:hover {
	filter: grayscale(100%) !important;
	background-color: white;
}

#darkMode.clickedDark {
	filter: grayscale(100%);
	background-color: white;
}

#darkMode.clickedDark:hover {
	filter: none !important;
	background-color: black;
}
